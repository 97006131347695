/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from './layout.module.css';
import { Provider } from "react-redux";
import { useSelector } from "react-redux";
import Header from "./header"
import Footer from "./Footer";
import { themes } from "../themes/theme";
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const theme = useSelector((state) => state?.theme?.view);

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */}
        <div
          className={styles.mainWrap}
          style={{
            backgroundColor: themes[theme].background
            // margin: `0 auto`,
            // maxWidth: `var(--size-content)`,
            // padding: `var(--size-gutter)`,
          }}
        >
          <main>{children}</main>
          <Footer />
        </div>
    </>
  )
}

export default Layout
