import * as React from 'react';
import { useSelector } from 'react-redux';
import { themes } from '../themes/theme';
import { Typography } from '@mui/material';
import { GitHub, LinkedIn, Twitter } from '@mui/icons-material';
import { Box } from '@mui/material';

function Footer() {
  const theme = useSelector((state)=>state?.theme?.view);
  return (
    <>
      <footer
        style={{
          // marginTop: `var(--space-5)`,
          display: 'flex',
          flexDirection: 'column',
          fontSize: `var(--font-sm)`,
          backgroundColor: themes[theme].foreground,
          color: themes[theme].text2,
          alignItems: `center`,
          justifyContent: `center`,
          // height: `100px`,
          // borderTop: '1px solid grey',
          borderRadius: '20px 20px 0 0',
          margin: '10px',
          padding: '1rem'
        }}
      >
        <Typography variant="p"> DESIGNED AND CREATED BY </Typography>
        <Typography variant="p"> TIMOTHY WONG © {new Date().getFullYear()}</Typography>
        <p>   </p>
        <Box sx={{flexDirection: 'row', m: 0 }}>
          <GitHub fontSize="small" style={{margin: '0 .4rem'}}/>
          <LinkedIn fontSize="small" style={{margin: '0 .4rem'}}/>
          <Twitter fontSize="small" style={{margin: '0 .4rem'}}/>
        </Box>
        {` `}
      </footer>
    </>
  )
}

export default Footer;