import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    value: '',
  },
  reducers: {
    saveSearch: (state, action) => {
      return action.payload;
    }
  }
})

export const { saveSearch } = searchSlice.actions;
export default searchSlice.reducer;
