import React from 'react';
import Layout from './src/components/layout';
import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import { Provider } from 'react-redux';
import store from './src/store/store';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
require("./overwritePrism.css");
require("prismjs/themes/prism-okaidia.css");


export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <Layout> {element}</Layout>
    </Provider>
  )
}