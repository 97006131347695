import * as React from 'react';

export const themes = {
  light: {
    foreground: '#ffffff',
    background: '#ffffff',
    hover_background: '#e3e3e3',
    neutral_background: '#d9dadb',
    neutral_test: '#000000',
    selection_test: '#000000',
    text: 'black',
    text1: '#333333',
    text2: '#6b6b6b'
  },
  dark: {
    foreground: '#08080a',
    background: '#222224',
    hover_background: '',
    neutral_background: '#333333',
    neutral_test: '#d8d4cf',
    selection_test: '#e8e6e3',
    text: 'white',
    text1: '#c4c4c4',
    text2: '#a1a1a1'

  },
}

