import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    view: 'dark',
  },
  reducers: {
    toggleTheme: (state) => {
      if (state.view=='dark') {
        state.view='light';
      } else if (state.view=='light') {
        state.view='dark';
      }
    }
  }
})

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
